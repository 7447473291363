<template>
	<div class="mb-20">

		<b-form v-if="isReady && printers.length" @submit.prevent="addAwb">
			<div class="row d-flex justify-content-center">
				<div class="col-md-5">

					<div class="small">
						<span class="float-right" title="Edit Printer Settings" v-b-tooltip.hover>
							<b-icon-pencil-square class="m-1 pointer text-success" @click="isReady=false"></b-icon-pencil-square>
						</span>
					</div>

					<b-form-input
						v-model="awb"
						@keypress.enter.prevent="addAwb"
						type="text"
						ref="fm_awb"
						:autofocus="awb ? false : true"
						placeholder="Scan or Enter AWB"
						required
						trim
					></b-form-input>

					<div class="input-scan" style="margin-top: -40px;margin-right: 8px;">
						<svg tabindex="2" xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
							<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"></path>
						</svg>
					</div>
				</div>
			</div>
		</b-form>

		<div v-else>
			<AssignPrinter :printers="printers" v-if="printers" />
		</div>

		<div style="margin-left: 15px;">
			<b-tabs content-class="mt-3">
				<b-tab @click="currentPage = 1; trStatus = 701;getRTOListing()" title="Pending" active> </b-tab>
				<b-tab @click="currentPage = 1; trStatus = 0;getRTOListing()" title="Printed"> </b-tab>
			</b-tabs>
		</div>
		<b-container fluid class="table-container" style=" margin-bottom: 30px;" >
			<b-table 
				sticky-header 
				striped 
				hover 
				id="rto-listing" 
				class="rto-table" 
				:busy.sync="loading" 
				:items="items"
				:fields="fields"
				sticky-header
				no-border-collapse
			>
				
				<!-- A custom formatted data column cell for field 'Date' -->
				<template v-slot:cell(rto_date)="data">
					<span class="w74 d-block">
						{{ data.item.rto_date | date}}
						{{ data.item.rto_date | time}}
					</span>
				</template>

				<!-- A custom formatted data column cell for field 'Date' -->
				<template v-slot:cell(created)="data">
					<span class="w74 d-block">
						{{ data.item.created | date}}
					</span>
				</template>


				<template v-slot:cell(rto_awb)="data">
					<span class="w74 d-block" v-if="data.item.rto_awb?.length">
						{{ data.item.rto_awb}}
					</span>

					<span v-else>
						{{ '-' }}
					</span>
				</template>

				<template v-slot:cell(action)="data">

					<span v-if="data.item?.rto_awb">
						<b-button 
							class="mt-26 ml-10"
							variant="primary"
							type="submit"
							size="sm"
							@click="printLabelForRTO(data.item.rto_awb)"
							:disabled="downloading[`${data.item.rto_awb}`]"
						>
							Print Label
						</b-button>
					</span>

					<span v-else>
						{{ 'Pending' }}
					</span>

				</template>

			</b-table>
		</b-container>

		<div class="pagination-bar">
			<div class="container-fluid mp">
				<div class="row mp">
 
					<div class="col-4 mp">
						<div class="d-inline-flex wsnw align-items-center">
							<div class="d-block w50px">
								<multiselect 
									class="pagination-items__pp" 
									v-model="perPage" 
									:searchable="false"
									:show-labels="false" 
									:allow-empty="false" 
									:options="pageOptions"
									@input="setPageNumber"
								></multiselect>
							</div>
							<span class="pl-8 fs12 lh24 text-gray-600 pagination-items"></span>
						</div>
					</div>

					<div class="col-8 text-right mp">
						<div class="d-inline-flex ml-auto wsnw align-items-center">
							<div class="fs12 lh24 text-gray-600 pr-14 per-page">
								Page: {{ currentPage }}
							</div>

							<b-button-group>
								<b-button 
									class="common-btn" 
									@click="prevPage" 
									:disabled="prevDisabled"
									variant="paginate left"
								>
									<i class="icon-back fs12"></i>
									<b-icon-arrow-left-square-fill></b-icon-arrow-left-square-fill>
								</b-button>

								<b-button 
									class="common-btn" 
									@click="nextPage" 
									:disabled="nextDisabled"
									variant="paginate right"
								>
									<i class="icon-arrow fs12"></i>
									<b-icon-arrow-right-square-fill></b-icon-arrow-right-square-fill>
								</b-button>

							</b-button-group>
						</div>
					</div>

				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Multiselect from "vue-multiselect"
import * as JSPM from 'jsprintmanager'
import AssignPrinter from '@/components/AssignPrinter.vue'

export default {

	name: 'RTO',
	title: 'RTO',

	components:{
		Multiselect,
		AssignPrinter
	},

	data() {
		return {
			loading: false,
			message: null,
			awb: '',
			trStatus: 701,
			pageOptions: [10, 25, 100],
			totalRows: 1,
			currentPage: 1,
			perPage: 25,
			totalPages: 1,
			prevDisabled: true,
			nextDisabled: true,
			printers: [],
			startPage: 1,
			pagination: {
				hasNext: true,
				hasPrev: true,
			},
			items: [],
			fields: [
				{
					key: 'created', 
					label: 'Booking Date'
				},
				{
					key: 'awb', 
					label: 'AWB'
				},
				{
					key: 'sy_awb', 
					label: 'Shypmax ID'
				},
				{
					key: 'rto_date', 
					label: 'RTO Date'
				},
				{
					key: 'rto_awb', 
					label: 'RTO AWB'
				},
				{
					key: 'action', 
					label: 'Action'
				}
			],
			downloading: [],
			filename: '',
			isReady: false,
		}
	},

	mounted() {

		this.getRTOListing();

		this.onInit();
		if((this.label_printer || this.barcode_printer)) {
			this.isReady = true
		}

	},


	methods: {

		onInit: function () {
			var _this = this;
			JSPM.JSPrintManager.license_url = `${process.env.VUE_APP_API_ENDPOINT}/wh-license`;
			JSPM.JSPrintManager.auto_reconnect = true;
			JSPM.JSPrintManager.start();
			JSPM.JSPrintManager.WS.onStatusChanged = function () {
				_this.getPrinters().then((p) => {
					_this.printers = p;
					_this.$nextTick();
				});
			};
		},

		getPrinters: function () {
			return new Promise((ok, err) => {
				let printers = [null];
				if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
					this.errormsg = ''
					JSPM.JSPrintManager.getPrinters()
						.then(function (myPrinters) {
							printers.push(...myPrinters);
							ok(printers);
						})
						.catch((e) => err(e));
				} else {
					console.warn("JSPM WS not open");
					this.errormsg = 'Please start JSPrintManager application.'
					ok(printers);
				}
			});
		},

		async getRTOListing() {

			try {

				let response = await this.axios.get(`/rto/orders?page=${this.currentPage}&offset=${this.perPage}&trStatus=${this.trStatus}`);

				if(response.data.success) {
					this.items = response.data.data.rto_orders.results;

					this.totalRows = this.items.length;

					this.pagination.hasNext = response.data.data.rto_orders.hasNext;
					this.pagination.hasPrev = response.data.data.rto_orders.hasPrev;

					this.setPagination();
				}
				else {
					this.popToast('Failed!', response?.data?.message ?? "Failed", 'danger');

					this.items = [];
					this.totalRows = 0;
					this.pagination.hasNext = false;
					this.pagination.hasPrev = false;
				}

			}
			catch(error) {
				this.popToast('Failed!', error?.response?.data?.message ?? "Failed", 'danger');
			}

		},

		async addAwb() {

			let inputAwb = this.awb;

			try {

				let response = await this.axios.post('/rto/scan', {'awb': inputAwb});

				if(response.data.success) {
					this.popToast('Success', response.data.message, 'success')
					this.printLabelOnPrinter(response.data.labelPath, inputAwb)
					this.getRTOListing();
				}
				else {
					this.popToast('Failed!', response?.data?.message ?? "Failed", 'danger');
				}

			}
			catch(error) {
				this.popToast('Failed!', error?.response?.data?.message ?? "Failed", 'danger');
			}

		},


		async printLabelForRTO(rtoAWB) {

			this.downloading[`${rtoAWB}`] = true;

			try {
				
				let response = await this.axios.post('/rto/label', {'awb': rtoAWB});

				if(response.data.success) {
					this.popToast('Success', response.data.message, 'success')
					this.printLabelOnPrinter(response.data.labelPath, rtoAWB)
				}
				else {
					this.popToast('Failed!', response?.data?.message ?? "Failed", 'danger');
				}
			
				this.downloading[`${rtoAWB}`] = false;

			}
			catch (error) {
				this.downloading[`${rtoAWB}`] = false;
				this.popToast('Failed!', error?.response?.data?.message ?? "Failed", 'danger');
			}

		},

		//Check JSPM WebSocket status
		jspmWSStatus() {
			if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
				return true;
			}else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Closed) {
				alert('JSPrintManager (JSPM) is not installed or not running in the print server! Download and install JSPM Client App from https://neodynamic.com/downloads/jspm');
				this.isReady = false
				return false;
			}
			else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Blocked) {
				alert('JSPM has blocked this website!');
				return false;
			}
		},


		printLabelOnPrinter(labelLink, awb) {

			if(this.jspmWSStatus()) {

				if(this.$store.getters.global.label_printer) {
					let cpj = new JSPM.ClientPrintJob();
					cpj.clientPrinter = new JSPM.InstalledPrinter(this.$store.getters.global.label_printer);

					//Set content to print... in this sample, a pdf file
					const myPdfFile = new JSPM.PrintFilePDF(labelLink, JSPM.FileSourceType.URL, `${awb}.pdf`, 1);

					//add file to print job
					cpj.files.push(myPdfFile);

					//Send print job to printer!
					cpj.sendToClient();
				}
			}

		},


		setPagination() {
			this.nextDisabled = this.pagination.hasNext == true ? false : true;
			this.prevDisabled = this.pagination.hasPrev == true ? false : true;
		},

		nextPage() {
			this.currentPage++;
			this.getRTOListing();
		},

		prevPage() {
			this.currentPage--;
			this.getRTOListing();
		},
		
		setPageNumber() {
			this.currentPage = 1
			this.getRTOListing();

			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr = [];

			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}

			this.prevDisabled = true;

			if (this.totalPages == 1) {
				this.nextDisabled = true;
			} 
			else if (this.totalPages > 1) {
				this.nextDisabled = false;
			}
		},

		updatePageNumber() {

			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr = [];

			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
		},

	},

	computed: {
		label_printer() {
			return this.$store.getters.global.label_printer || null
		},
		seller_doc_printer() {
			return this.$store.getters.global.seller_doc_printer || null
		}
	},

}
</script>

<style lang="scss" scoped>
.rto {
	margin: 0px 0px 0px 0px;
}

.table-container {
	height: calc(100vh - 225px);
	overflow: visible;
	overflow-y: scroll;
}

.rto-table {
	height: 100%;
}

.b-table-sticky-header {
	overflow-y: auto;
	max-height: 100%;
}

.drop-file {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 20px;
}

.upload {
	margin: 0px 0px 0px 10px;
	padding: 0.3rem 0.75rem;
}

.pagination-bar {
	width: 97%;
	margin: auto;
	position: fixed;
	bottom: 20px;
}

.common-btn {
	font-size: 1.3rem;
	margin: 0px 3px 0px 0px;
	padding: 0px;
	border: 0px;
	border-radius: 0px;
	cursor: pointer;
}

.per-page {
	margin: 0px 10px 0px 0px;
}

.mp {
	margin: 0px !important;
	padding: 0px !important;
}
</style>
